.profile-header {
  padding-top: 95px;
  padding-bottom: 95px;
  max-width: 1210px;
  padding: 95px 35px;
  margin: 0 auto;
  @include flex-center(space-between);
  position: relative;

  .left-content {
    max-width: 474px;

    .user-name {
      @include d-center;

      h5 {
        font-weight: 700;
        text-transform: lowercase;
        padding-left: 5px;
      }
    }

    .profile-headlines {
      padding-bottom: 25px;
      padding-top: 10px;

      p {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 250px;
        text-wrap: wrap;
      }
    }

    .profile-controls {
      @include d-center;

      .more-opt {
        width: 36px;
        height: 36px;
        border: 1px solid var(--black);
        border-radius: 100%;
        @include flex-center;
        margin: 0 30px 0 15px;
      }

      .user-acc {
        cursor: pointer;

        p {
          color: var(--medium-grey);

          img {
            padding-left: 5px;
          }
        }
      }
    }
  }

  .right-content {
    @include flex-center;

    .follow {
      @include flex-center;
      flex-direction: column;

      p {
        font-weight: 700;
        padding-bottom: 10px;
        font-size: 16px;
        color: var(--black);
      }

      &:nth-child(2) {
        margin-left: 40px;
        margin-right: 65px;
      }
    }

    .profile-img {
      width: 135px;
      height: 135px;
      border: 1.8px solid var(--black);
      border-radius: 100%;
      @include flex-center;
      padding: 6px;

      img {
        border-radius: 100%;
      }
    }

    .followings {
      .follow {
        label {
          font-size: 14px;
          color: var(--medium-grey);
          text-transform: capitalize;
        }
      }
    }
  }

  .private-btn {
    position: absolute;
    top: 20px;
    right: 20px;

    button {
      font-family: "Inter";
      font-weight: 500;
      font-size: 12px;
      display: flex;
      align-items: center;
      background: var(--warning-20);
      border: none;
      padding: 0px 14px;
      border-radius: 35px;

      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }
  }
}

.more-menu-sm {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 30px 22px 22px 19px;
  background-color: var(--white);
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  z-index: 10;

  .menu-head {
    @include flex-center(space-between);
    margin-bottom: 26px;
  }

  .address {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1023px) {
  .profile-header .left-content .profile-controls .user-acc {
    display: none;
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .profile-header {
    align-items: flex-start;
    flex-direction: column;
    padding-top: 65px;

    .right-content {
      width: 100%;
      justify-content: space-between;

      .followings {
        order: 2;
      }

      .follow:nth-child(2) {
        margin-left: 40px;
        margin-right: 0px;
      }

      .profile-img {
        width: 81px;
        height: 81px;
        padding: 4px;
      }
    }

    .left-content {
      margin-top: 63px;
      order: 2;
      max-width: none;
      width: 100%;

      .user-name h5 {
        font-size: 23px;
      }

      .profile-controls {
        display: flex;
        justify-content: space-between;

        .user-acc {
          display: flex;
        }
      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 599px) {
  .profile-header {
    align-items: flex-start;
    flex-direction: column;
    // padding-top: 32px;
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 45px;

    .left-content {
      margin-top: 0px;
      max-width: none;
      width: 100%;

      .user-name h5 {
        font-size: 23px;
        line-break: anywhere;
      }

      .profile-controls {
        display: flex;
        justify-content: space-between;

        .user-acc {
          display: none;
        }
      }
    }

    .right-content {
      width: 100%;
      justify-content: space-between;
      order: 2;
      margin-top: 31px;

      .followings {
        order: 2;
      }

      .follow:nth-child(2) {
        margin-left: 25px;
        margin-right: 0px;
      }

      .profile-img {
        width: 81px;
        height: 81px;
        padding: 4px;
      }
    }
  }
}

.home-page {
  margin-top: 90px;
}

.video-icon {
  right: 16px;
  top: 16px;
  z-index: 3;
}