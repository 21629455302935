.catalyst-content {
  // background: black;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    // color: #fff;
  }
  padding-top: 80px;
  padding-bottom: 120px;
  .bb {
    border-bottom: 1px solid var(--lightest-grey);
  }
  .bt {
    border-top: 1px solid var(--lightest-grey);
  }
  .gap-48 {
    gap: 48px;
  }

  .links {
    padding: 10px 0;
  }
}

.privacy-page {
  padding-top: 80px;

  ul {
    padding-left: 20px;
    li {
      list-style: disc;
      list-style-position: inside;
      font-size: 18px;
      color: black;
      font-weight: 500;
    }
  }
  ol {
    padding-left: 20px;
    li {
      list-style: decimal;
      list-style-position: inside;
      font-size: 18px;
      color: black;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 767px) {
  .catalyst-content {
    padding-top: 120px;

    h4 {
      font-size: 32px;
      line-height: 120%;
    }
  }
}
