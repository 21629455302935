.news {
  padding: 75px 0;
  overflow-x: hidden;

  .news-slider {
    max-width: 557px;
    margin: auto;
    margin-top: 25px;

    .news-card {
      width: 100%;

      .news-header {
        height: 493px;
        border: 1px solid var(--black);
        padding: 10px;
      }

      .news-body {
        margin-top: 30px;
        @include d-col-center;

        p {
          text-align: center;
          margin-bottom: 30px;
          margin-top: 25px;
          white-space: nowrap;
          /* Prevent text from wrapping */
          overflow: hidden;
          /* Hide overflowing text */
          text-overflow: ellipsis;
          /* Add ellipses at the end */

          display: block;
          max-width: 97%;
          /* Ensure proper behavior with ellipsis */

          span {
            font-weight: 700;
          }
        }

        label {
          margin-top: 15px;
        }
      }

      .news-status {
        background: black;
        max-width: 90%;
        width: 100%;
        position: absolute;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        padding: 8px 10px 8px 15px;
        border-radius: 8px;
        height: 30px;
        display: flex;
        align-items: center;
        // .circle {
        //   width: 7px;
        //   height: 7px;
        //   border-radius: 100%;
        //   background: white;
        //   display: block;
        //   position: relative;
        //   &:after {
        //     content: "";
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     background: rgba(255, 255, 255, 0.33);
        //     transform: scale(2);
        //     border-radius: 100%;
        //     z-index: -1;
        //   }
        // }

        .circle {
          width: 7px;
          height: 7px;
          position: relative;

          &:before {
            content: "";
            position: relative;
            display: block;
            width: 300%;
            height: 300%;
            box-sizing: border-box;
            margin-left: -100%;
            margin-top: -100%;
            border-radius: 45px;
            background-color: #fff;
            animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1)
              infinite;
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: white;
            border-radius: 15px;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
            animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
              infinite;
          }
        }

        .member-ship {
          padding: 2px 5px;
          border-radius: 3px;

          &.gold {
            background: #fff0e3;
            color: #733407;
          }

          &.silver {
            background: #e8ecf4;
            color: #5280de;
          }

          &.bronze {
            background: #ffebe9;
            color: #ea7025;
          }

          &.standard {
            background: rgba(246, 146, 255, 0.2);
            color: #ca46d6;
          }
        }
      }
    }

    .slick-arrow.slick-next {
      @include slick-arrow-size;
      right: -60px;

      &::before {
        content: url("../assets/icon/arrow-right-thin.svg");
      }
    }

    .slick-arrow.slick-prev {
      @include slick-arrow-size;
      left: -60px;

      &::before {
        content: url("../assets/icon/arrow-left-thin.svg");
      }
    }

    .carousel-control-next {
      bottom: 85px;
      opacity: 1;
      right: -80px;

      .carousel-control-next-icon {
        background-image: url("../assets/icon/arrow-right-thin.svg") !important;
        width: 24px;
      }
    }

    .carousel-control-prev {
      bottom: 85px;
      opacity: 1;
      left: -80px;

      .carousel-control-prev-icon {
        background-image: url("../assets/icon/arrow-left-thin.svg") !important;
        width: 24px;
      }
    }

    .carousel-item {
      transition: all 0.4s ease-in-out;
    }
  }
}

@media screen and (max-width: 600px) {
  .news {
    padding: 155.71px 23px 23px 23px;

    .news-slider {
      max-width: 367px;

      .news-card {
        .news-header {
          height: 353px;
        }
      }

      .slick-arrow.slick-next {
        @include slick-arrow-size(-40px);
        right: -10px;
      }

      .slick-arrow.slick-prev {
        @include slick-arrow-size(-40px);
        left: -10px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .news .news-slider .news-card .news-header {
    height: auto;
    width: 100%;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }

  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

.member-ship {
  padding: 2px 5px;
  border-radius: 7px;

  &.gold {
    background: #fff0e3;
    color: #733407;
  }

  &.silver {
    background: #e8ecf4;
    color: #5280de;
  }

  &.bronze {
    background: #ffebe9;
    color: #ea7025;
  }

  &.standard {
    background: rgba(246, 146, 255, 0.2);
    color: #ca46d6;
  }
}
