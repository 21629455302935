.textarea-box {
  width: 100%;
  min-height: 41px;
  position: relative;
  outline: 1px solid var(--input-border);
  border-radius: 20px;
  overflow: hidden;
  @include flex-center;
  margin: 14px 0 10px 0;
  img {
    position: absolute;
    right: 5px;
    // background: white;
    border-radius: 70px;
    top: 8px;
    &.abouterror {
      top: 8px;
    }
  }

  textarea {
    width: 100%;
    min-height: 130px;
    padding: 11px 19.5px;
    overflow-y: auto;
    border: none;
    padding-right: 27px;
    overflow: auto;
    font-size: 14px;
    font-weight: 500;
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: var(--light-grey) !important;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &:disabled {
      outline: 1px dashed var(--medium-grey);
      background-color: rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3);
      background-color: var(--lightest-grey);
      outline: 1px dashed var(--medium-grey);
    }
  }

  &:hover {
    outline: 1px solid var(--black);
  }
  &:focus-within {
    // border: 2px solid var(--black);
    outline: 2px solid var(--black);
  }
  &.disabled {
    background-color: var(--lightest-grey);
    outline: 1px dashed var(--medium-grey);
  }
  // .input {
  //   &::placeholder {
  //     color: var(--light-grey) !important;
  //   }
  //   &:focus-within {
  //     border: 1px solid red;
  //   }
  // }
}
