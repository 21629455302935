.card-skelton {
  width: 100%;
  border: 1px solid var(--light-grey);
  .header {
    display: flex;
    align-items: center;
    padding: 21px;
    justify-content: space-between;
    .skelton {
      border-radius: 30px;
      height: 15px !important;
    }
  }
  .skelton1 {
    width: 130px !important;
  }
  .skelton2 {
    width: 110px !important;
  }
  .skelton3 {
    width: 80px !important;
  }
  .skelton4 {
    width: 60px !important;
  }
  .skelton5 {
    width: 140px !important;
  }

  .divider {
    width: 1px;
    height: 32px;
    background-color: var(--light-grey);
  }
  .body {
    .image {
      width: 100%;
      height: 353px;
      background: #f0f0f0;
    }
  }
}
